<template>
  <main>
    <CCard>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol col="6">
            <h5 class="mb-0">{{ $t('Navigation.Order/MainRegion') }}</h5>
          </CCol>
          <CCol col="6" class="text-md-right">
            <CButtonGroup class="mr-2">
              <CButton size="sm" :color="(StatisticsSwitch === 'Total' ? 'info' : 'light')"  @click="StatisticsSwitch = 'Total'">{{ $t('Order.Total') }}</CButton>
              <CButton size="sm" :color="(StatisticsSwitch === 'SubTotal' ? 'info' : 'light')"  @click="StatisticsSwitch = 'SubTotal'">{{ $t('Order.SubTotal') }}</CButton>
            </CButtonGroup>
            <CDropdown size="sm" color="danger" placement="bottom-start" class="d-inline-block">
              <template #toggler-content>
                <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Order.OrderAction') }}
              </template>
              <CDropdownHeader>
                {{ $t('Order.PrintShipmentList') }}
              </CDropdownHeader>
              <CDropdownItem @click="PrintShipmentList()">
                {{ $t('Order.PrintShipmentListOption.ExportSelect') }}
              </CDropdownItem>
              <CDropdownItem @click="PrintShipmentList(true)">
                {{ $t('Order.PrintShipmentListOption.ExportFilterCondition') }}
              </CDropdownItem>
              <CDropdownDivider />
              <template v-if="Features.includes('ContentPickingList')">
                <CDropdownHeader>
                  {{ $t('Order.PrintShipmentList_Content') }}
                </CDropdownHeader>
                <CDropdownItem @click="PrintShipmentContentList()">
                  {{ $t('Order.PrintShipmentListOption.ExportSelect') }}
                </CDropdownItem>
                <CDropdownItem @click="PrintShipmentContentList(true)">
                  {{ $t('Order.PrintShipmentListOption.ExportFilterCondition') }}
                </CDropdownItem>
                <CDropdownDivider />
              </template>
              <CDropdownHeader>
                {{ $t('Order.PrintOrderCSV') }}
              </CDropdownHeader>
              <CDropdownItem @click="ExportOrderCSV()">
                {{ $t('Order.PrintOrderCSVOptions.ExportSelect') }}
              </CDropdownItem>
              <CDropdownItem @click="ExportOrderCSV(true)">
                {{ $t('Order.PrintOrderCSVOptions.ExportFilterCondition') }}
              </CDropdownItem>
              <CDropdownHeader>
                {{ $t('Order.PrintOrderDetailCSV') }}
              </CDropdownHeader>
              <CDropdownItem @click="ExportOrderDetailCSV()">
                {{ $t('Order.PrintOrderDetailCSVOptions.ExportSelect') }}
              </CDropdownItem>
              <CDropdownItem @click="ExportOrderDetailCSV(true)">
                {{ $t('Order.PrintOrderDetailCSVOptions.ExportFilterCondition') }}
              </CDropdownItem>
              <CDropdownDivider />
              <CDropdownHeader>
                {{ $t('Order.BulkImport') }}
              </CDropdownHeader>
              <CDropdownItem @click="BulkImportOrder('GoogleSheets')">
                {{ $t('Order.BulkImportOrderGoogleSheets') }}
              </CDropdownItem>
              <CDropdownItem @click="BulkImportLogisticsData()">
                {{ $t('Order.LogisticsTracking.BulkImport') }}
              </CDropdownItem>
              <CDropdownDivider />
              <CDropdownItem @click="ShowMoreOrderAction">
                {{ $t('Order.MoreOrderAction') }}
              </CDropdownItem>
            </CDropdown>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto px-2">
        <CRow class="text-nowrap">
          <CCol col="12">
            <CButton color="white" shape="pill" class="mr-2" @click="OpenFilter('All')">
              <CIcon name="cil-filter"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
              {{ $t('Order.Filter.DataTime') }}: {{
                SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD HH:mm:ss') : '--'
              }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Buyer')">
              {{ $t('Order.Buyer') }}: {{ SearchFilter.Buyer || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('BuyerPhone')">
              {{ $t('Order.BuyerPhone') }}: {{ SearchFilter.BuyerPhone || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('OrderNum')">
              {{ $t('Order.OrderNum') }}: {{ SearchFilter.OrderNum.join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('ProductName')">
              {{ $t('Order.Filter.ProductName') }}: {{ SearchFilter.ProductName || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Payment')">
              {{ $t('Order.PaymentType') }}: {{ SearchFilter.Payment.map(item => item.label).join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Logistics')">
              {{ $t('Order.LogisticsType') }}: {{ SearchFilter.Logistics.map(item => item.label).join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('PaymentStatus')">
              {{ $t('Order.PaymentStatus') }}: {{
                SearchFilter.PaymentStatus.map(item => item.label).join(',') || '--'
              }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('LogisticsStatus')">
              {{ $t('Order.LogisticsStatus') }}:
              {{ SearchFilter.LogisticsStatus.map(item => item.label).join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('OrderStatus')">
              {{ $t('Order.OrderStatus') }}: {{ SearchFilter.OrderStatus.map(item => item.label).join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('PerPage')">
              {{ $t('Order.Filter.PerPage') }}: {{ Pagination.PerPage }} {{ $t('Global.OrderUnit') }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <!--            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Sort')">-->
            <!--              {{ $t('Order.Filter.Sort') }}: {{ $t('Order/List.SortType.' + this.Order) || '&#45;&#45;' }}-->
            <!--              <CIcon size="sm" class="ml-1" name="cil-pencil"/>-->
            <!--            </CButton>-->
            <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetState()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto">
        <CRow v-if="StatisticsSwitch === 'Total'" class="text-nowrap" style="min-width: 900px;">
          <CCol col>
            <CCallout color="success" class="my-1">
              <small class="text-muted">訂單金額總計</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ TotalStatistics.TotalOrderAmount || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">實際金額總計 <i v-c-tooltip="{content: '商品金額總計扣除優惠金額總計',placement: 'right'}" class="fas fa-question-circle text-info" /></small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ TotalStatistics.TotalRealAmount || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">商品金額總計 <i v-c-tooltip="{content: '所有訂單商品金額累計',placement: 'right'}" class="fas fa-question-circle text-info" /></small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ TotalStatistics.TotalProductAmount || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">優惠金額總計 <i v-c-tooltip="{content: '所有訂單優惠金額累計，不包含購物金等錢包額度',placement: 'right'}" class="fas fa-question-circle text-info" /></small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ TotalStatistics.TotalOfferAmount || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="warning" class="my-1">
              <small class="text-muted">訂單數量總計</small><br>
              <strong class="h4">{{ TotalStatistics.TotalItems || '0' }}</strong>
            </CCallout>
          </CCol>
        </CRow>
        <CRow v-if="StatisticsSwitch === 'SubTotal'" class="text-nowrap" style="min-width: 900px;">
          <CCol col>
            <CCallout color="success" class="my-1">
              <small class="text-muted">訂單金額小計 <i v-c-tooltip="{content: '當前頁面的訂單金額總計',placement: 'right'}" class="fas fa-question-circle text-info" /></small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ SubTotalStatistics.SubTotalOrderAmount || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">實際金額小計 <i v-c-tooltip="{content: '當前頁面的訂單商品金額總計扣除優惠金額總計',placement: 'right'}" class="fas fa-question-circle text-info" /></small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ SubTotalStatistics.SubTotalRealAmount || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">商品金額小計 <i v-c-tooltip="{content: '當前頁面的訂單商品金額累計',placement: 'right'}" class="fas fa-question-circle text-info" /></small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ SubTotalStatistics.SubTotalProductAmount || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">優惠金額小計 <i v-c-tooltip="{content: '當前頁面的訂單優惠金額累計，不包含購物金等錢包額度',placement: 'right'}" class="fas fa-question-circle text-info" /></small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ SubTotalStatistics.SubTotalOfferAmount || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="warning" class="my-1">
              <small class="text-muted">訂單數量小計</small><br>
              <strong class="h4">{{ SubTotalStatistics.SubTotalItems || '0' }}</strong>
            </CCallout>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CDataTable
          id="OrderList"
          :items="List"
          :fields="Field"
          :loading="Loading"
          :noItemsView="noItemsView"
          addTableClasses="small text-nowrap"
          responsive
          hover
        >
          <template #Checkbox-header>
            <label class="mb-0">
              <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
            </label>
          </template>
          <template #Checkbox="{item}">
            <td class="text-center">
              <label class="mb-0">
                <input type="checkbox" :id="'Checkbox-List-' + item.OrderNum" :value="item.OrderNum"
                  v-model="ChooseIndex">
              </label>
            </td>
          </template>
          <template #OrderNum="{item}">
            <td>
              <a href="#" @click="OpenWindow(`/order/detail/${item.OrderNum}`)" class="text-info">
                {{ item.OrderNum }}
              </a>
              <p class="mb-0">
                <CBadge class="mr-1" :color="(item.UserID === '0' ? 'warning' : 'success')">{{ (item.UserID === '0' ? '訪客' : '會員') }}</CBadge>
                <template v-if="Array.isArray(item.Variable.Tags)">
                  <CBadge v-for="Tag in item.Variable.Tags" :key="Tag" class="mr-1" color="danger">{{ $i18n.messages[$i18n.locale].Tags[Tag] ? $i18n.messages[$i18n.locale].Tags[Tag] : Tag }}</CBadge>
                </template>
              </p>
            </td>
          </template>
          <template #OrderInfo="{item}">
            <td>
              <p class="mb-0"><b>{{ $t('Order.CreateTime') }}</b>: {{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}</p>
              <p class="mb-0">
                <b>{{ $t('Order.Origin') }}</b>: {{ $t('Order.OriginOptions.' + item.OriginType) }}<span v-if="item.OriginType !== 'Default'">({{ item.OriginPage ? item.OriginPage : '' }})</span></p>
            </td>
          </template>
          <template #Buyer="{item}">
            <td>
              <p class="mb-0"><b>{{ $t('Order.Buyer') }}</b>:
                <a v-if="item.UserID !== '0'" href="javascript:void(0)" @click="OpenUserModal(item.UserID)" class="text-info">
                  {{ item.MemberInfo.Name }}
                </a>
                <span v-else>{{ item.MemberInfo.Name }}</span>
              </p>
              <p class="mb-0"><b>{{ $t('Order.Phone') }}</b>: {{ item.MemberInfo.Phone }}</p>
            </td>
          </template>
          <template #Amount="{item}">
            <td>
              <p class="mb-0"><b>{{ $t('Order.RealAmount') }}</b>: {{ $store.state.currency + '$' }}{{ item.RealAmount }}</p>
              <p class="mb-0"><b>{{ $t('Order.Amount') }}</b>: <span
                class="text-danger">{{ $store.state.currency + '$' }}{{ item.TotalAmount }}</span></p>
            </td>
          </template>
          <template #PaymentAndLogisticsType="{item}">
            <td>
              <p class="mb-0"><b>{{ $t('Order.PaymentType') }}</b>: {{ $t('Payment.PaymentMethods.' + item.Payment.Method) }}({{ $t('Payment.PaymentSystem.' + item.Payment.System) }})</p>
              <p class="mb-0"><b>{{ $t('Order.LogisticsType') }}</b>: {{ $t('Logistics.LogisticsMethods.' + item.Logistics.Method) }}({{ $t('Logistics.LogisticsSystem.' + item.Logistics.System) }})</p>
            </td>
          </template>
          <template #PaymentType="{item}">
            <td>
              {{ $t('Payment.PaymentMethods.' + item.Payment.Method) }}({{ $t('Payment.PaymentSystem.' + item.Payment.System) }})
            </td>
          </template>
          <template #LogisticsType="{item}">
            <td>
              {{ $t('Logistics.LogisticsMethods.' + item.Logistics.Method) }}({{ $t('Logistics.LogisticsSystem.' + item.Logistics.System) }})
            </td>
          </template>
          <template #PaymentStatus="{item}">
            <td>
              <CBadge :color="StatusClassOption[item.Status.Payment]">
                {{ $t('Order.PaymentStatusList.' + item.Status.Payment) }}
              </CBadge>
            </td>
          </template>
          <template #LogisticsStatus="{item}">
            <td>
              <CBadge :color="StatusClassOption[item.Status.Logistics]">
                {{ $t('Order.LogisticsStatusList.' + item.Status.Logistics) }}
              </CBadge>
            </td>
          </template>
          <template #OrderStatus="{item}">
            <td>
              <CBadge :color="StatusClassOption[item.Status.Order]">
                {{ $t('Order.OrderStatusList.' + item.Status.Order) }}
              </CBadge>
            </td>
          </template>
          <template #Action="{item}">
            <td>
              <CButton color="info" size="sm" class="mr-1" v-c-tooltip="$t('Order.ChangeStatus')" @click="OpenChangeStatusModal(item)">
                <CIcon name="cil-pencil" class="c-icon-custom-size mr-1"/>
              </CButton>
              <CButton v-if="(item.Status.Payment === 'Y' || item.Status.Payment === 'A') && item.Status.Logistics === 'P' && item.Logistics.System !== 'CUSTOM'" class="mr-1" color="light" size="sm" v-c-tooltip="$t('Order.CreateLogisticsOrder')" @click="OpenCreateLogisticsOrderModal(item.OrderNum, item.Logistics.System)">
                <CIcon name="cil-truck" class="c-icon-custom-size" />
              </CButton>
              <CButton v-if="(item.Status.Logistics === 'U' || item.Status.Logistics === 'H') && item.Logistics.System !== 'CUSTOM'" color="light" class="mr-1" size="sm" v-c-tooltip="$t('Order.PrintSingleLabel')" @click="RedirectSinglePrintShipmentLabel(item.OrderNum)">
                <CIcon name="cil-print" class="c-icon-custom-size" />
              </CButton>
              <CButton v-if="(item.Status.Payment === 'C')" color="danger" size="sm" class="mr-1" v-c-tooltip="$t('Order.Refund')" @click="Refund(item)">
                <CIcon name="cil-action-undo" class="c-icon-custom-size"/>
              </CButton>
              <CButton v-if="(item.Status.Order !== 'N')" color="danger" size="sm" class="mr-1" v-c-tooltip="$t('Global.Cancel')" @click="CancelOrder(item)">
                <CIcon name="cil-x" class="c-icon-custom-size"/>
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CPagination :activePage.sync="Pagination.Page" :pages.sync="Pagination.TotalPages"
          @update:activePage="ChangePage()"/>
      </CCardFooter>
    </CCard>
    <CModal id="FilterModel" class="ActionModel" :size="(ShowFilter !== 'All' ? 'md' : 'lg')" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit"
            @update:show="ChangePage()">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="dateTime" is24hr :max-date="$dayjs(SearchFilter.EndTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="dateTime" is24hr :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.TimeRangeOptions') }}
        </CCol>
        <CCol sm="9">
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().format('YYYY-MM-DD 00:00:00'), $dayjs().format('YYYY-MM-DD 23:59:59'))">
            今日
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00'), $dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59'))">
            昨日
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(6, 'day').format('YYYY-MM-DD 00:00:00'), $dayjs().format('YYYY-MM-DD 23:59:59'))">
            本週
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(2, 'week').format('YYYY-MM-DD 00:00:00'), $dayjs().subtract(1, 'week').format('YYYY-MM-DD 23:59:59'))">
            上週
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().format('YYYY-MM-01 00:00:00'), $dayjs().format('YYYY-MM-DD 23:59:59'))">
            本月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().startOf('month').subtract(1, 'month').format('YYYY-MM-DD'), $dayjs().startOf('month').format('YYYY-MM-DD'))">
            上月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss'), $dayjs().format('YYYY-MM-DD HH:mm:ss'))">
            近一個月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(3, 'month').format('YYYY-MM-DD HH:mm:ss'), $dayjs().format('YYYY-MM-DD HH:mm:ss'))">
            近三個月
          </CButton>
        </CCol>
        <CCol sm="12">
          <hr>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.ReceiverDate') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.ReceiverDate" mode="date">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().format('YYYY-MM-DD 00:00:00'), $dayjs().format('YYYY-MM-DD 23:59:59'), 'ReceiverDate')">
            今日
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00'), $dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59'), 'ReceiverDate')">
            昨日
          </CButton>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'OrderNum'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.OrderNum') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.OrderNum"
            v-model="SearchFilter.OrderNum"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="SearchFilter.OrderNum"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Buyer'" v-model="SearchFilter.Buyer" :label="$t('Order.Buyer')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'BuyerPhone'" v-model="SearchFilter.BuyerPhone" :label="$t('Order.BuyerPhone')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'BuyerEmail'" v-model="SearchFilter.BuyerEmail" :label="$t('Order.BuyerEmail')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Receiver'" v-model="SearchFilter.Receiver" :label="$t('Order.Receiver')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'ReceiverPhone'" v-model="SearchFilter.ReceiverPhone" :label="$t('Order.ReceiverPhone')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'ReceiverEmail'" v-model="SearchFilter.ReceiverEmail" :label="$t('Order.ReceiverEmail')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'ProductName'" v-model="SearchFilter.ProductName" :label="$t('Order.Filter.ProductName')" type="text" horizontal />
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'SeriesNum'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.SeriesNum') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.SeriesNum"
            v-model="SearchFilter.SeriesNum"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="SearchFilter.SeriesNum"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'InvoiceType'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.InvoiceType') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.InvoiceType"
            v-model="SearchFilter.InvoiceType"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="InvoiceTypeList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'InvoiceNumber'" v-model="SearchFilter.InvoiceNumber" :label="$t('Order.Invoice.Number')" type="text" horizontal />
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Payment'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.PaymentType') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Payment"
            v-model="SearchFilter.Payment"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="PaymentTypeList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Logistics'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsType') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Logistics"
            v-model="SearchFilter.Logistics"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="LogisticsTypeList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'PaymentSystem'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.PaymentSystem') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.PaymentSystem"
            v-model="SearchFilter.PaymentSystem"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="PaymentSystemList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'LogisticsSystem'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsSystem') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.LogisticsSystem"
            v-model="SearchFilter.LogisticsSystem"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="LogisticsSystemList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'PaymentStatus'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.PaymentStatus') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.PaymentStatus"
            v-model="SearchFilter.PaymentStatus"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="PaymentStatusList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'LogisticsStatus'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsStatus') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.LogisticsStatus"
            v-model="SearchFilter.LogisticsStatus"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="LogisticsStatusList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'LogisticsNum'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.LogisticsNum') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.LogisticsNum"
            v-model="SearchFilter.LogisticsNum"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="SearchFilter.LogisticsNum"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'OrderStatus'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.OrderStatus') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.OrderStatus"
            v-model="SearchFilter.OrderStatus"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="OrderStatusList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Origin'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Origin') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Origin"
            v-model="SearchFilter.Origin"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="PageList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'Sort'" horizontal :label="$t('Order.Filter.Sort')" :options="SortOption" v-model="Order" :value.sync="Order" :placeholder="$t('Global.PleaseSelect')"/>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'PerPage'" horizontal :label="$t('Order.Filter.PerPage')" :options="PerPageOption" v-model="Pagination.PerPage" :value.sync="Pagination.PerPage" :placeholder="$t('Global.PleaseSelect')"/>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangePage()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <component :is="Component" :Toggle.sync="ToggleModal" :OrderData.sync="SelectOrderData" :AllCondition.sync="AllCondition" :SelectSystem.sync="SelectSystem" :OrderNum.sync="SelectOrderNum" :UserID="UserID" :UserType="UserType" @Success="GetList()" />
  </main>
</template>

<route>
{
"meta": {
"label": "訂單管理"
}
}
</route>

<script>
export default {
  name: 'OrderList',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data() {
    return {
      Component: null,
      List: [],
      Features: [],
      StatisticsSwitch: 'Total',
      SubTotalStatistics: {},
      TotalStatistics: {},
      ShowFilter: 'All',
      SearchFilterType: null,
      SearchFilter: {
        StartTime: '',
        EndTime: '',
        ReceiverDate: '',
        SearchAllTimeRange: false,
        OrderNum: [],
        Payment: [],
        Logistics: [],
        PaymentSystem: [],
        LogisticsSystem: [],
        PaymentStatus: [],
        LogisticsStatus: [],
        OrderStatus: [],
        Account: [],
        Buyer: '',
        Receiver: '',
        BuyerPhone: '',
        ReceiverPhone: '',
        BuyerEmail: '',
        ReceiverEmail: '',
        SeriesNum: [],
        ProductName: '',
        OfferType: [],
        OfferID: [],
        InvoiceType: [],
        InvoiceNumber: '',
        LogisticsNum: [],
        Origin: []
      },
      AllCondition: false,
      AlertModel: false,
      FilterModel: false,
      ToggleModal: false,
      SelectOrderNum: '',
      SelectOrderData: {},
      SelectSystem: '',
      StatusClassOption: {
        'P': 'dark',
        'R': 'light',
        'RF': 'light',
        'U': 'warning',
        'C': 'secondary',
        'F': 'danger',
        'N': 'danger',
        'X': 'danger',
        'Y': 'success',
        'H': 'info',
        'S': 'primary',
        'O': 'info',
        'A': 'primary',
      },
      ChooseIndex: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'nto',
      SearchAllTimeRange: true,
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Order.NoResults'),
        noItems: this.$t('Order.NoItems')
      },
      PageList: [
        { label: this.$t('Offer.OfficialWeb'), value: 'Default' }
      ],
      UserID: '',
      UserType: ''
    }
  },
  computed: {
    Field() {
      return [
        {key: 'Checkbox', label: ''},
        {key: 'OrderNum', label: this.$t('Order.OrderNum')},
        {key: 'OrderInfo', label: this.$t('Order.Info')},
        {key: 'Buyer', label: this.$t('Order.Buyer')},
        {key: 'Amount', label: this.$t('Order.Amount')},
        // {key: 'PaymentType', label: this.$t('Order.PaymentTypeSystem')},
        // {key: 'LogisticsType', label: this.$t('Order.LogisticsTypeSystem')},
        {key: 'PaymentAndLogisticsType', label: this.$t('Order.PaymentAndLogisticsTypeSystem')},
        {key: 'PaymentStatus', label: this.$t('Order.PaymentStatus')},
        {key: 'LogisticsStatus', label: this.$t('Order.LogisticsStatus')},
        {key: 'OrderStatus', label: this.$t('Order.OrderStatus')},
        {key: 'Action', label: ''},
      ]
    },
    SortOption() {
      return Object.keys(this.$t('Order/List.SortType')).map(type => {
        return {
          value: type,
          label: this.$t('Order/List.SortType')[type]
        }
      })
    },
    PerPageOption() {
      return Object.keys(this.$t('Order/List.PerPageOption')).map(option => {
        return {
          value: option,
          label: this.$t('Order/List.PerPageOption')[option]
        }
      })
    },
    InvoiceTypeList() {
      return Object.keys(this.$t('Order.InvoiceType')).map(type => {
        return {
          value: type,
          label: this.$t('Order.InvoiceType')[type]
        }
      })
    },
    OfferTypeList() {
      return Object.keys(this.$t('Order.Taxonomy')).map(taxonomy => {
        return {
          value: taxonomy,
          label: this.$t('Order.PaymentStatusList')[taxonomy]
        }
      })
    },
    PaymentStatusList() {
      return Object.keys(this.$t('Order.PaymentStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.PaymentStatusList')[status]
        }
      })
    },
    LogisticsStatusList() {
      return Object.keys(this.$t('Order.LogisticsStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.LogisticsStatusList')[status]
        }
      })
    },
    OrderStatusList() {
      return Object.keys(this.$t('Order.OrderStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.OrderStatusList')[status]
        }
      })
    },
    PaymentTypeList() {
      return Object.keys(this.$t('Payment.PaymentMethods')).map(status => {
        return {
          value: status,
          label: this.$t('Payment.PaymentMethods')[status]
        }
      })
    },
    LogisticsTypeList() {
      return Object.keys(this.$t('Logistics.LogisticsMethods')).map(status => {
        return {
          value: status,
          label: this.$t('Logistics.LogisticsMethods')[status]
        }
      })
    },
    PaymentSystemList() {
      return Object.keys(this.$t('Payment.PaymentSystem')).map(status => {
        return {
          value: status,
          label: this.$t('Payment.PaymentSystem')[status]
        }
      })
    },
    LogisticsSystemList() {
      return Object.keys(this.$t('Logistics.LogisticsSystem')).map(status => {
        return {
          value: status,
          label: this.$t('Logistics.LogisticsSystem')[status]
        }
      })
    }
  },
  created() {
    const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
    this.Features = PermissionSession.Features.Order || []
  },
  mounted() {
    this.$Progress.start()
    return this.Init().then(() => {
      this.GetSinglePageList()
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList()
      ]).then(() => {
        Object.keys(this.$route.query).forEach((param) => {
          switch (param) {
            case 'page':
            case 'perPage':
            case 'order':
              break
            case 'SeriesNum':
            case 'PaymentStatus':
            case 'LogisticsStatus':
            case 'OrderStatus':
            case 'InvoiceType':
            case 'OfferType':
            case 'PaymentSystem':
            case 'LogisticsSystem':
              this.SearchFilter[param] = this[`${param}List`].filter(data => this.$route.query[param].split(',').includes(data.value))
              break
            case 'Payment':
            case 'Logistics':
              this.SearchFilter[param] = this[`${param}TypeList`].filter(data => this.$route.query[param].split(',').includes(data.value))
              break
            case 'StartTime':
            case 'EndTime':
              this.SearchFilter[param] = parseInt(this.$route.query[param]) * 1000
              break
            case 'Origin':
              this.SearchFilter[param] = this.PageList.filter(data => this.$route.query[param].split(',').includes(data.value))
              break
            case 'Buyer':
            case 'Receiver':
            case 'BuyerPhone':
            case 'ReceiverPhone':
            case 'BuyerEmail':
            case 'ReceiverEmail':
            case 'ProductName':
            case 'SearchAllTimeRange':
            case 'ReceiverDate':
              this.SearchFilter[param] = this.$route.query[param]
              break
            default:
              this.SearchFilter[param] = this.$route.query[param].split(',')
              break
          }
        })
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      const RequestQuery = {
        Taxonomy: 'ProductOrder',
        Page: this.$route.query.page || this.Pagination.Page,
        PerPage: this.$route.query.perPage || this.Pagination.PerPage,
        Order: this.$route.query.order || this.Order,
        ReceiverCountry: 'TW,TW-MAIN,TW-OUTLYING'
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        RequestQuery[param] = this.$route.query[param]
      })
      this.ChooseIndex = []
      this.List = []
      this.SubTotalStatistics = {}
      this.TotalStatistics = {}
      this.Loading = true
      this.FilterModel = false
      return this.$store.dispatch('InnerRequest', {
        url: '/order/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.SubTotalStatistics = data.subTotalStatistics || {}
        this.TotalStatistics = data.totalStatistics || {}
        this.Pagination = data.pagination
        this.Pagination = {
          Page: data.pagination.Page,
          PerPage: data.pagination.PerPage.toString(),
          TotalItems: data.pagination.TotalItems,
          TotalPages: data.pagination.TotalPages
        }
        this.SearchFilter.StartTime = data.pagination.TimeRange.StartTime
        this.SearchFilter.EndTime = data.pagination.TimeRange.EndTime
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        switch (typeof this.SearchFilter[param]) {
          case "object":
            if (Array.isArray(this.SearchFilter[param]) && this.SearchFilter[param].length > 0) {
              switch (param) {
                case 'PaymentStatus':
                case 'LogisticsStatus':
                case 'OrderStatus':
                case 'Payment':
                case 'Logistics':
                case 'PaymentSystem':
                case 'LogisticsSystem':
                case 'InvoiceType':
                case 'OfferType':
                case 'Origin':
                  query[param] = this.SearchFilter[param].map(item => item.value).join(',')
                  break
                default:
                  query[param] = this.SearchFilter[param].join(',')
                  break
              }
            } else {
              switch (param) {
                case 'StartTime':
                case 'EndTime':
                  query[param] = this.$dayjs(this.SearchFilter[param]).unix()
                  break
                case 'ReceiverDate':
                  query[param] = this.$dayjs(this.SearchFilter[param]).format('YYYY-MM-DD')
                  break
                case 'UTM':
                  for (const [key, val] of Object.entries(this.SearchFilter[param])) {
                    if (val !== '') query[key] = val
                  }
                  break
              }
            }
            break
          case "string":
          case "number":
            switch (param) {
              case 'StartTime':
              case 'EndTime':
                query[param] = this.$dayjs(this.SearchFilter[param]).unix()
                break
              default:
                if (this.SearchFilter[param] !== '') {
                  query[param] = this.SearchFilter[param]
                }
                break
            }
            break
        }
      })
      return this.$router.replace({
        path: '/order/region/main',
        query
      }).then(() => {
        this.Init()
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseIndex = this.List.map(data => {
          return data.OrderNum
        })
      } else {
        this.CheckAllValue = false
        this.ChooseIndex = []
      }
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      if (id === 'OrderNum') {
        newTag = newTag.trim().toUpperCase()
      }
      this.SearchFilter[id].push(newTag)
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-DD 00:00:00')).subtract(1, 'month').unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().format('YYYY-MM-DD 23:59:59')).unix() * 1000,
        ReceiverDate: '',
        SearchAllTimeRange: false,
        OrderNum: [],
        Payment: [],
        Logistics: [],
        PaymentSystem: [],
        LogisticsSystem: [],
        PaymentStatus: [],
        LogisticsStatus: [],
        OrderStatus: [],
        Account: [],
        Buyer: '',
        Receiver: '',
        BuyerPhone: '',
        ReceiverPhone: '',
        BuyerEmail: '',
        ReceiverEmail: '',
        SeriesNum: [],
        ProductName: '',
        OfferType: [],
        OfferID: [],
        InvoiceType: [],
        InvoiceNumber: '',
        LogisticsNum: [],
        Origin: []
      }
      this.ChooseIndex = []
      return this.$router.replace({
        path: '/order/region/main'
      }).then(() => {
        this.Init()
      })
    },
    OpenWindow(_link) {
      return window.open(_link, '_blank');
    },
    GetSinglePageList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/page/shop/list',
        method: 'get',
        params: {
          page: 1,
          perPage: 60,
          order: 'nto'
        }
      }).then(({data}) => {
        this.Loading = false
        const SinglePage = data.list.map(item => {
          return {
            label: `${item.Name} (${item.Slug})`,
            value: 'SinglePage-' + item.Slug
          }
        })
        this.PageList = [
          ...this.PageList,
          ...SinglePage
        ]
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    OpenCreateLogisticsOrderModal (orderNum, system) {
      try {
        this.Component = require('@/components/order/CreateShipment/' + system.toLowerCase()).default
      } catch (error) {
        this.Component = require('@/components/order/CreateLogisticsOrder').default
      }
      setTimeout(() => {
        this.SelectOrderNum = orderNum
        this.ToggleModal = true
      }, 300)
    },
    OpenLogisticsOrderInfoModal (orderNum) {
      this.Component= () => import('@/components/order/LogisticsOrderInfo')
      setTimeout(() => {
        this.SelectOrderNum = orderNum
        this.ToggleModal = true
      }, 300)
    },
    OpenChangeStatusModal (orderData) {
      this.Component = () => import('@/components/order/ChangeStatus')
      setTimeout(() => {
        this.ToggleModal = true
        this.SelectOrderData = orderData
      }, 300)
    },
    async PrintShipmentList (all = false) {
      this.Submit = true
      this.$Progress.start()
      if (all) {
        const RequestQuery = {}
        Object.keys(this.SearchFilter).forEach((param) => {
          RequestQuery[param] = this.$route.query[param]
        })
        this.ChooseIndex = await this.$store.dispatch('InnerRequest', {
          url: '/order/systemList',
          method: 'get',
          params: RequestQuery
        }).then(({ data }) => {
          return data.list.map(item => item.OrderNum)
        }).catch(() => {
          return false
        })
        if (!this.ChooseIndex) {
          this.Submit = false
          this.$Progress.fail()
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Order/GetOrderFail'),
            type: 'warn'
          })
        }
      }
      if (this.ChooseIndex.length === 0) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/order/exportShipmentList',
        method: 'post',
        data: {
          orderIDs: this.ChooseIndex
        }
      }).then((response) => {
        this.Submit = false
        this.$Progress.finish()
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/pdf'})
        return window.open(URL.createObjectURL(Data), '_blank')
      }).catch((err) => {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
        throw err
      })
    },
    async PrintShipmentContentList (all = false) {
      this.Submit = true
      this.$Progress.start()
      if (all) {
        const RequestQuery = {}
        Object.keys(this.SearchFilter).forEach((param) => {
          RequestQuery[param] = this.$route.query[param]
        })
        this.ChooseIndex = await this.$store.dispatch('InnerRequest', {
          url: '/order/systemList',
          method: 'get',
          params: RequestQuery
        }).then(({ data }) => {
          return data.list.map(item => item.OrderNum)
        }).catch(() => {
          return false
        })
        if (!this.ChooseIndex) {
          this.Submit = false
          this.$Progress.fail()
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Order/GetOrderFail'),
            type: 'warn'
          })
        }
      }
      if (this.ChooseIndex.length === 0) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/order/exportShipmentContentList',
        method: 'post',
        data: {
          orderIDs: this.ChooseIndex
        }
      }).then((response) => {
        this.Submit = false
        this.$Progress.finish()
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/pdf'})
        return window.open(URL.createObjectURL(Data), '_blank')
      }).catch((err) => {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
        throw err
      })
    },
    ShowMoreOrderAction() {
      this.Component = () => import('@/components/order/BulkActionSidebar')
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
    BulkCreateShipmentOrder(system, all = false) {
      this.AllCondition = all
      if (this.ChooseIndex.length === 0 && !all) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      try {
        this.SelectOrderNum = this.ChooseIndex
        this.Component = () => import('@/components/order/BulkCreateShipment/' + system)
        setTimeout(() => {
          this.ToggleModal = true
        }, 300)
      } catch (error) {
        console.log(error)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error,
          type: 'error'
        })
      }
    },
    async BulkPrintLabel(system, all = false) {
      this.Submit = true
      this.$Progress.start()
      const RequestQuery = {
        LogisticsSystem: system.toUpperCase(),
        LogisticsStatus: ['H', 'U'].join(','),
        PaymentStatus: ['Y', 'A'].join(',')
      }
      if (all) {
        Object.keys(this.SearchFilter).forEach((param) => {
          if (typeof this.$route.query[param] !== 'undefined') {
            RequestQuery[param] = this.$route.query[param]
          }
        })
        this.ChooseIndex = await this.$store.dispatch('InnerRequest', {
          url: '/order/systemList',
          method: 'get',
          params: RequestQuery
        }).then(({ data }) => {
          return data.list.map(item => item.OrderNum)
        }).catch((error) => {
          console.log(error)
          return false
        })
      }
      if (this.ChooseIndex.length === 0 || this.ChooseIndex === false) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/order/exportShipmentLabel',
        method: 'post',
        data: {
          System: system,
          OrderData: this.ChooseIndex
        }
      }).then((response) => {
        this.Submit = false
        this.$Progress.finish()
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/pdf'})
        return window.open(URL.createObjectURL(Data), '_blank')
      }).catch((err) => {
        throw err
      })
    },
    Refund (orderData) {
      return this.$swal({
        icon: 'info',
        title: this.$t('Message.Info'),
        text: '此操作將會同時作廢發票，部分付款系統目前不支援API退款(如綠界與藍新)，您仍需要至該付款系統之管理平台內進行退款，請確認是否要進行退款動作。',
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Execute') + this.$t('Order.Refund'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.Submit = true
          this.$Progress.start()
          return this.$store.dispatch('InnerRequest', {
            url: '/order/refund',
            method: 'post',
            data: orderData
          }).then(() => {
            this.Submit = false
            this.$Progress.finish()
            this.Init()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Order/RefundSuccess'),
              type: 'success'
            })
          }).catch((error) => {
            this.Submit = false
            this.$Progress.fail()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Order/RefundFail') + error.msg || this.$t('Message.Global/SystemError'),
              type: 'error'
            })
            throw error
          })
        }
      })
    },
    CancelOrder (orderData) {
      return this.$swal({
        icon: 'question',
        title: '是否取消訂單？',
        text: '此操作將會通知顧客此訂單已取消，同時取消後將會退回已使用購物金並可進行退款，是否確認執行？',
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Confirm'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start()
          return this.$store.dispatch('InnerRequest', {
            url: '/order/edit',
            method: 'post',
            data: {
              orderNum: orderData.OrderNum,
              origin: 'Manage',
              updateData: {
                'Status.Payment': 'C',
                'Status.Logistics': 'N',
                'Status.Order': 'N'
              }
            }
          }).then(() => {
            this.Submit = false
            this.$Progress.finish()
            this.Init()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Order/OrderCancelSuccess'),
              type: 'success'
            })
          }).catch((error) => {
            this.Submit = false
            this.$Progress.fail()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Order/OrderCancelFail') + error.Message,
              type: (error.type ? error.type : 'error')
            })
          })
        }
      })
    },
    async RedirectSinglePrintShipmentLabel(orderNum) {
      this.Submit = true
      this.$Progress.start()
      const PayCenterOrderData = await this.$store.dispatch('InnerRequest', {
        url: '/order/detail',
        method: 'get',
        params: {
          orderNum
        }
      }).then(({ data }) => {
        return data.detail
      }).catch((error) => {
        console.log(error)
        return false
      })
      this.Submit = false
      this.$Progress.finish()
      switch (PayCenterOrderData.Logistics.System) {
        case 'ECPAY-LOGISTICS':
          return window.open(`/api/order/redirectPrintShipmentLabel?Token=${localStorage.getItem('XX-CSRF-Token')}&System=${PayCenterOrderData.Logistics.System.toUpperCase()}&Method=${PayCenterOrderData.Logistics.Method.toUpperCase()}&OrderData=${PayCenterOrderData.LogisticsData.OrderNo}`, '_blank')
        default:
          if (PayCenterOrderData.LogisticsData.LabelImage && PayCenterOrderData.LogisticsData.LabelImage.includes('http')) {
            return window.open(PayCenterOrderData.LogisticsData.LabelImage, '_blank')
          } else if (PayCenterOrderData.Logistics.System !== 'CUSTOM') {
            return window.open(`/api/order/redirectPrintShipmentLabel?Token=${localStorage.getItem('XX-CSRF-Token')}&System=${PayCenterOrderData.Logistics.System.toUpperCase()}&Method=${PayCenterOrderData.Logistics.Method.toUpperCase()}&OrderData=${PayCenterOrderData.LogisticsData.OrderNo}`, '_blank')
          } else {
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Warning'),
              text: this.$t('Order.NoConditionOrderPrint'),
              type: 'warn'
            })
          }
          break
      }
    },
    async RedirectPrintShipmentLabel(system, method, all = true) {
      this.Submit = true
      this.$Progress.start()
      let RequestQuery = {}
      if (!all && this.ChooseIndex.length === 0 || this.ChooseIndex === false) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        if (typeof this.$route.query[param] !== 'undefined') {
          RequestQuery[param] = this.$route.query[param]
        }
      })
      RequestQuery = {
        ...RequestQuery,
        StartTime: this.$dayjs(this.SearchFilter.StartTime).unix(),
        EndTime: this.$dayjs(this.SearchFilter.EndTime).unix(),
        Logistics: method.toUpperCase(),
        LogisticsSystem: system.toUpperCase(),
        LogisticsStatus: ['H', 'U'].join(','),
        PaymentStatus: ['Y', 'A'].join(',')
      }
      const PayCenterOrderNo = await this.$store.dispatch('InnerRequest', {
        url: '/order/systemList',
        method: 'get',
        params: RequestQuery
      }).then(({ data }) => {
        return data.list.filter(item => typeof item.LogisticsData?.OrderNo !== 'undefined').map(item => item.LogisticsData.OrderNo)
      }).catch((error) => {
        console.log(error)
        return false
      })
      this.Submit = false
      this.$Progress.finish()
      if (PayCenterOrderNo.length > 0) {
        return window.open(`/api/order/redirectPrintShipmentLabel?Token=${localStorage.getItem('XX-CSRF-Token')}&System=${system.toUpperCase()}&Method=${method.toUpperCase()}&OrderData=${PayCenterOrderNo.join(',')}`, '_blank')
      } else {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.NoOrderPrint'),
          type: 'warn'
        })
      }
    },
    async ExportOrderCSV(all = false) {
      this.Submit = true
      this.$Progress.start()
      if (all) {
        const RequestQuery = {
          Order: 'nto'
        }
        Object.keys(this.SearchFilter).forEach((param) => {
          RequestQuery[param] = this.$route.query[param]
        })
        this.ChooseIndex = await this.$store.dispatch('InnerRequest', {
          url: '/order/systemList',
          method: 'get',
          params: RequestQuery
        }).then(({ data }) => {
          return data.list.map(item => item.OrderNum)
        }).catch(() => {
          return false
        })
      }
      if (this.ChooseIndex.length === 0) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/order/exportOrderCSV',
        method: 'post',
        data: {
          orderIDs: this.ChooseIndex
        }
      }).then((response) => {
        this.Submit = false
        this.$Progress.finish()
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `訂單總表-${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
        throw err
      })
    },
    async ExportOrderDetailCSV(all = false) {
      this.Submit = true
      this.$Progress.start()
      if (all) {
        const RequestQuery = {
          Order: 'nto'
        }
        Object.keys(this.SearchFilter).forEach((param) => {
          RequestQuery[param] = this.$route.query[param]
        })
        this.ChooseIndex = await this.$store.dispatch('InnerRequest', {
          url: '/order/systemList',
          method: 'get',
          params: RequestQuery
        }).then(({ data }) => {
          return data.list.map(item => item.OrderNum)
        }).catch(() => {
          return false
        })
      }
      if (this.ChooseIndex.length === 0) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/order/exportOrderDetailCSV',
        method: 'post',
        data: {
          orderIDs: this.ChooseIndex
        }
      }).then((response) => {
        this.Submit = false
        this.$Progress.finish()
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `訂單明細-${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
        throw err
      })
    },
    BulkImportLogisticsData() {
      this.Component = () => import('@/components/order/BulkImportLogisticsData')
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
    BulkImportOrder (type = 'GoogleSheets') {
      switch (type) {
        case 'GoogleSheets':
          this.Component = () => import('@/components/order/BulkImportOrder/GoogleSheets')
          break
        default:
          return false
      }
      setTimeout(() => {
        this.ToggleModal = true
      }, 500)
    },
    async ExportLogisticsList(type) {
      this.Submit = true
      this.$Progress.start()
      const RequestQuery = {
        Page: 1,
        PerPage: 500,
        Order: 'nto'
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        RequestQuery[param] = this.$route.query[param]
      })
      this.ChooseIndex = await this.$store.dispatch('InnerRequest', {
        url: '/order/list',
        method: 'get',
        params: RequestQuery
      }).then(({ data }) => {
        return data.list.map(item => item.OrderNum)
      }).catch(() => {
        return false
      })
      return this.$store.dispatch('InnerRequest', {
        url: '/order/exportLogisticsList',
        method: 'post',
        data: {
          LogisticsType: type,
          orderIDs: this.ChooseIndex
        }
      }).then((response) => {
        this.Submit = false
        this.$Progress.finish()
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `${this.$t(`Order.ExportLogisticsList.${type}`)}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
        throw err
      })
    },
    TransReport (system) {
      this.Component = () => import('@/components/order/TransReport')
      this.SelectSystem = system
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
    ApplyTimeRange (StartTime, EndTime, FilterParameters) {
      if (FilterParameters) {
        this.SearchFilter.ReceiverDate = this.$dayjs(StartTime).format('YYYY-MM-DD').toString()
      } else {
        this.SearchFilter.StartTime = this.$dayjs(StartTime).unix() * 1000
        this.SearchFilter.EndTime = this.$dayjs(EndTime).unix() * 1000
      }
    },
    OpenUserModal(userID) {
      this.Component = () => import('@/components/member/UserModel')
      this.UserID = userID
      this.UserType = 'Read'
      setTimeout(() => {
        this.ToggleModal = true
      }, 500)
    }
  }
}
</script>

<style>
#OrderList thead th:first-child {
  text-align: center;
}

#OrderList tbody td:first-child {
  vertical-align: middle;
}
</style>
